import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo/Seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Row>
        <Col>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
